import { instance } from "@apis/instance";

import {
  GetPlatformSalesParams,
  GetPlatformSalesSummaryParams,
  getDailySalesParams,
  getSalesDetailParams,
} from "./types";

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 건별 매출 요약 정보를 가져오는 함수입니다.
 * @param params
 */
export const getPlatformSalesSummary = async (
  params: GetPlatformSalesSummaryParams,
) => {
  const depositors = params?.depositor?.split(",");

  const { data } = await instance({
    method: "get",
    url: `/v2/sales/platform-sales/summary`,
    params: {
      ...params,
      depositor: params?.depositor?.includes("ALL") ? undefined : depositors,
    },
  });

  return data.data;
};

/**
 * startDate 와 endDate 를 파라미터로 받아와서 해당 기간 내의 건별 매출 내역를 가져오는 함수입니다.
 * @param params
 * @param page
 */
export const getPlatformSales = async (
  params: GetPlatformSalesParams,
  page: number,
) => {
  const depositors = params?.depositor?.split(",");

  const { data } = await instance({
    method: "get",
    url: `/v2/sales/platform-sales`,
    params: {
      page: page,
      ...params,
      depositor: params?.depositor?.includes("ALL") ? undefined : depositors,
    },
  });

  return data;
};

/**
 * daily sales 를 가져오는 api 호출 함수입니다.
 * @param params
 */
export const getDailySalesPlatform = async (params: getDailySalesParams) => {
  const { data } = await instance({
    method: "get",
    url: `v1/sales/platform-dailysale`,
    params: {
      ordering: "-sold_at",
      ...params,
    },
  });

  return data;
};

/**
 * daily count 를 가져오는 api 호출 함수입니다.
 * @param params
 */
export const getSalesStatistics = async (params: getSalesDetailParams) => {
  const { data } = await instance({
    method: "get",
    url: `v1/sales/statistics`,
    params: {
      ...params,
    },
  });

  return data;
};

/**
 *  를 가져오는 api 호출 함수입니다.
 * @param params
 */
export const getSalesCount = async (params: getSalesDetailParams) => {
  const { data } = await instance({
    method: "get",
    url: `v1/sales/category`,
    params: {
      ...params,
    },
  });

  return data;
};

export const getSalesAvg = async (params: getSalesDetailParams) => {
  const { data } = await instance({
    method: "get",
    url: `v1/sales/avg`,
    params: {
      ...params,
    },
  });

  return data;
};

export const getSalesSummary = async (params: getSalesDetailParams) => {
  const { data } = await instance({
    method: "get",
    url: `/v1/sales/summary`,
    params: {
      ...params,
    },
  });

  return data;
};
