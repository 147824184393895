import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./SignaturePad.module.scss";
import { SignaturePadProps } from "./SignaturePad.types";
import SignatureCanvas from "react-signature-canvas";
import "@ui/styles/index.scss";
import { BottomSheet, Icon, Text, VStack } from "@ui/components/atoms";
import { ReloadIcon } from "@earlybird/icons";
import { trackEventMixpanel } from "@earlypay/shared";

/**
 * `SignaturePad` 는 은행을 선택하는 데에 사용하기 위한 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <SignaturePad
 *   visible={visible}
 *   onClose={onClose}
 *   handleSaveSignature={handleSaveSignature}
 * />
 * ```
 */
export const SignaturePad = forwardRef<HTMLElement, SignaturePadProps>(
  function SignaturePad(
    {
      className,
      as,
      visible,
      onClose,
      title = "내용을 이해하셨다면 서명해주세요",
      handleSaveSignature,
      ...rest
    }: SignaturePadProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const [isSigned, setIsSigned] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const canvasRef = useRef(null);

    const signGuideImage = isSigned
      ? "none"
      : `url(${import.meta.env.VITE_S3_URI}/images/service/signature-guide.png)`;

    const handleConvertImage = () => {
      setLoading(true);
      const trimmedCanvas = canvasRef.current.getTrimmedCanvas();

      const image = trimmedCanvas.toDataURL("image/png");
      trackEventMixpanel("save_signature_image", {
        description: "서명 이미지 저장",
      });
      handleSaveSignature(image);
      setLoading(false);
      onClose();
    };

    const checkSignatureSize = () => {
      const trimmedCanvas = canvasRef.current.getTrimmedCanvas();
      const trimmedWidth = trimmedCanvas.width;
      const trimmedHeight = trimmedCanvas.height;

      if (trimmedWidth > 30 && trimmedHeight > 30) {
        return setDisabled(false);
      }

      setDisabled(true);
    };

    const handleClickReset = () => {
      setDisabled(true);
      setIsSigned(false);
      canvasRef.current.clear();
    };

    useEffect(() => {
      if (!visible) return;

      if (visible) {
        handleClickReset();
        // 컴포넌트가 보일 때 캔버스를 초기화
        const canvas = canvasRef.current?.getCanvas();
        if (canvas) {
          canvas.width = canvas.clientWidth;
          canvas.height = canvas.clientHeight;
        }
      }
    }, [canvasRef, visible]);

    return (
      <BottomSheet
        {...rest}
        ref={forwardedRef}
        className={classNames(
          styles.SignaturePad,
          "earlybird-signature-pad",
          className,
        )}
        visible={visible}
        headerType={"headline"}
        title={title}
        buttonType={"single"}
        primaryButtonLabel={"완료"}
        disabled={disabled}
        onClickPrimaryButton={handleConvertImage}
        loading={loading}
        onClose={onClose}
      >
        <VStack spacing={8} center>
          <SignatureCanvas
            ref={canvasRef}
            canvasProps={{
              className: classNames(styles.Canvas),
              style: {
                backgroundImage: signGuideImage,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              },
            }}
            clearOnResize={true}
            onBegin={() => {
              setIsSigned(true);
            }}
            onEnd={checkSignatureSize}
          />

          <button
            className={classNames(styles.ResetButton)}
            onClick={handleClickReset}
          >
            <Icon icon={ReloadIcon} size={"xs"} />
            <Text typo={"body-3"}>다시 쓰기</Text>
          </button>
        </VStack>
      </BottomSheet>
    );
  },
);

export default SignaturePad;
