import React, { useCallback, useEffect, useState } from "react";

import {
  createFormDataWithPdf,
  getFormattedDateWithDots,
  trackEventMixpanel,
  trackPageViewMixpanel,
} from "@earlypay/shared";
import { termsCode, termsCodeTypes } from "@earlypay/shared/typings";
import { useRecoilValue } from "recoil";

import { ActionButton, AgreementList, Text } from "@earlybird/ui";
import { AgreementDetails, PageContainer } from "@earlybird/ui";

import { Header, Meta } from "@components/layouts";

import { useRedirectUsers } from "@apis/hooks";
import { useUpdateDocument } from "@apis/hooks/document/mutations";

import { applicationState } from "@/recoil/application/atom";

export const ServiceAgreement = () => {
  const application = useRecoilValue(applicationState);
  const today = getFormattedDateWithDots();

  const [termsState, setTermsState] = useState("check");
  const [visibleTermsModal, setVisibleTermsModal] = useState(false);

  const mutation = useUpdateDocument(application.id);
  const redirectMutation = useRedirectUsers(application.user.id);

  const handleSaveTerms = useCallback((file: File | Blob) => {
    const formData = createFormDataWithPdf(file, "imageServiceAgreement");
    mutation.mutate(formData);
  }, []);

  useEffect(() => {
    if (mutation.isSuccess) {
      trackEventMixpanel("complete_service_agreement", {
        description: "서비스 이용 약관 동의 단계 완료",
      });
      redirectMutation.mutate("/service-apply/gateway");
    }
  }, [mutation.isSuccess]);

  /** 약관을 `확인`만 하기 위해서 클릭했을 때 호출하는 함수입니다. */
  const handleClickTerm = (code: termsCodeTypes) => {
    setTermsState("check");
    setVisibleTermsModal(true);
  };

  /** 약관을 `동의`하기 위해서 클릭했을 때 호출하는 함수입니다. */
  const handleClickAgreement = () => {
    setTermsState("agree");
    setVisibleTermsModal(true);
  };

  return (
    <>
      <Meta
        title={`얼리페이 | 서비스 신청`}
        name={"얼리페이 서비스 이용약관 동의"}
      />
      <Header
        previous={
          application.businessRegistrationNumber &&
          application.address &&
          application.detailAddress
            ? "/service-apply/license-verify/verify"
            : "/service-apply/license-verify"
        }
      ></Header>

      <PageContainer spacing={16}>
        <Text bold typo={"subtitle-1"}>
          {"얼리페이가 처음이시군요!\n이용 전 동의가 필요해요"}
        </Text>

        <AgreementList
          title={"(주)얼리페이"}
          terms={[termsCode.EP01]}
          handleClickTerm={handleClickTerm}
        />

        <AgreementDetails
          state={termsState}
          visible={visibleTermsModal}
          onClose={() => setVisibleTermsModal(false)}
          terms={[termsCode.EP01]}
          handleSaveTerms={handleSaveTerms}
          username={application.user.name}
          date={today}
        />
      </PageContainer>

      <ActionButton
        primaryButtonLabel={"모두 동의하기"}
        loading={mutation.isPending}
        onClickPrimaryButton={handleClickAgreement}
        primaryButtonProperty={{
          description: "얼리페이 서비스 이용 약관 동의 버튼",
        }}
      />
    </>
  );
};

export default ServiceAgreement;
