import React, { useRef, useState } from "react";

import { trackEventMixpanel, trackPageViewMixpanel } from "@earlypay/shared";

import { Meta } from "@components/layouts";
import IntroSlide from "@components/templates/serviceApply/IntroSlide";
import { ServiceApplyContainer } from "@components/templates/serviceApply/ServiceApplyContainer";

import useNavigateWithParams from "@hooks/useNavigateParams";

const Intro: React.FC = () => {
  const slideRef = useRef(null);
  const { navigateWithParams } = useNavigateWithParams();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleClickNextButton = () => {
    if (currentIndex === 2) {
      trackEventMixpanel("complete_intro", {
        description: "인트로 단계 완료",
      });
      navigateWithParams({ pathname: "/service-apply/sales-type" });
      return;
    }
    setCurrentIndex(currentIndex + 1);
    slideRef?.current?.slickNext();
  };

  const titleArray = [
    "복잡한 서류 없이\n가입은 간편하게",
    "약정이나 위약금 없이\n언제든지 자유롭게",
    "첫 달은 부담없이 \n무료로 써보고 결정하세요",
  ];

  return (
    <>
      <Meta title={`얼리페이 | 서비스 신청`} name={"인트로"} />
      <ServiceApplyContainer
        title={titleArray[currentIndex]}
        buttonLabel={currentIndex === 2 ? "3분만에 서비스 신청하기" : "다음"}
        onClickButton={handleClickNextButton}
        spacing={64}
      >
        <IntroSlide
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          slideRef={slideRef}
        />
      </ServiceApplyContainer>
    </>
  );
};

export default Intro;
