import { getErrorResponse, sentryHook } from "@earlypay/shared";
import { ServerError, modalCode } from "@earlypay/shared/typings";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useConfirmModal } from "@earlybird/ui";

import useNavigateParams from "@hooks/useNavigateParams";
import useUploadImage from "@hooks/useUploadImage";

import { postApplicationDocument } from "@apis/endpoints/applications";
import { postMaskingBusinessLicense } from "@apis/endpoints/applications";
import { postVerifyBusinessLicense } from "@apis/endpoints/applications";
import { applicationsQueryKeys } from "@apis/hooks/applications/queryKeys";

export const useMaskingBusinessLicense = (applicationId: number) => {
  const queryClient = useQueryClient();
  const { openConfirmModal } = useConfirmModal();
  const { navigateWithParams } = useNavigateParams();
  const { handleOpenImageMethodSelector } = useUploadImage();

  return useMutation({
    mutationFn: (payload: FormData) =>
      postMaskingBusinessLicense(applicationId, payload),
    onError: (error: ServerError) => {
      const errorResponse = getErrorResponse(error);

      sentryHook({
        title: "사업자 등록증 OCR 추출에 실패했습니다.",
        error: error,
      });

      if (errorResponse && "code" in errorResponse) {
        switch (errorResponse.code) {
          case "invalid-doc-type":
            return openConfirmModal({
              id: modalCode.WARNING_INVALID_DOC_TYPE_BUSINESS_LICENSE,
            });
          case "invalid-format":
          case "image-too-small":
            return openConfirmModal({
              id: modalCode.WARNING_INVALID_IMAGE_FILE,
              onClickPrimaryButton: handleOpenImageMethodSelector,
            });
          case "bad-image":
            return openConfirmModal({
              id: modalCode.WARNING_INVALID_OCR_BUSINESS_LICENSE,
              onClickPrimaryButton: handleOpenImageMethodSelector,
            });
          case "max-retry-exceeded":
            return openConfirmModal({ id: modalCode.ERROR_MAX_RETRY_EXCEEDED });
          case "unknown":
            return openConfirmModal({ id: modalCode.ERROR_UNKNOWN });
          case "disable-corporate":
          case "closed-business":
            return navigateWithParams({
              pathname: `/service-apply/license-verify/result`,
              query: { errorCode: errorResponse.code },
            });
          default:
            return openConfirmModal({ id: modalCode.ERROR_UNKNOWN });
        }
      }

      openConfirmModal({ id: modalCode.ERROR_UNKNOWN });

      return error;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    retry: 0,
  });
};

export const useVerifyBusinessLicense = (applicationId: number) => {
  const queryClient = useQueryClient();
  const { openConfirmModal } = useConfirmModal();
  const { handleOpenImageMethodSelector } = useUploadImage();
  const { navigateWithParams } = useNavigateParams();

  return useMutation({
    mutationFn: () => postVerifyBusinessLicense(applicationId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    onError: (error: ServerError) => {
      const errorResponse = getErrorResponse(error);

      sentryHook({
        title: "사업자 등록증 정보 검증에 실패했습니다.",
        error: error,
      });

      if (errorResponse && "code" in errorResponse) {
        switch (errorResponse.code) {
          case "disable-corporate":
          case "closed-business":
            return navigateWithParams({
              pathname: `/service-apply/license-verify/result`,
              query: { errorCode: errorResponse.code },
            });
          case "duplicate-registration-number":
            return openConfirmModal({
              id: modalCode.WARNING_INVALID_DOC_TYPE_BUSINESS_LICENSE,
            });
          case "online-business":
            return openConfirmModal({
              id: modalCode.WARNING_ONLINE_BUSINESS_LICENSE,
              onClickPrimaryButton: () =>
                navigateWithParams({
                  pathname: `/service-apply/license-verify/verify`,
                }),
              onClickSecondaryButton: () =>
                navigateWithParams({
                  pathname: `/service-apply/license-verify/result`,
                  query: { errorCode: errorResponse.code },
                }),
            });
          case "invalid-business":
            return openConfirmModal({ id: modalCode.WARNING_INVALID_OCR });
          case "different-user":
            return openConfirmModal({
              id: modalCode.WARNING_DIFFERENT_BUSINESS_LICENSE,
            });
          case "bad-image":
            return openConfirmModal({
              id: modalCode.WARNING_INVALID_OCR_BUSINESS_LICENSE,
              onClickPrimaryButton: handleOpenImageMethodSelector,
            });
          case "max-retry-exceeded":
            return openConfirmModal({ id: modalCode.ERROR_MAX_RETRY_EXCEEDED });
          case "unknown":
            return openConfirmModal({ id: modalCode.ERROR_UNKNOWN });
          default:
            return openConfirmModal({ id: modalCode.ERROR_UNKNOWN });
        }
      }

      openConfirmModal({ id: modalCode.ERROR_UNKNOWN });

      return error;
    },
    retry: 0,
  });
};

export const useUpdateDocument = (applicationId: number) => {
  const queryClient = useQueryClient();
  const { openConfirmModal } = useConfirmModal();

  return useMutation({
    mutationFn: async (payload: FormData) => {
      await postApplicationDocument(applicationId, payload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.fetchApplication().queryKey,
      });
    },
    onError: (error: ServerError) => {
      sentryHook({
        title: "얼리페이 서비스 서류 제출에 실패했습니다.",
        error: error,
      });

      openConfirmModal({ id: modalCode.ERROR_UNKNOWN });
    },
    retry: 0,
  });
};
