import { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import styles from "./SocialLoginButton.module.scss";
import { SocialLoginButtonProps } from "./SocialLoginButton.types";
import "@ui/styles/index.scss";
import { Icon, Text } from "@ui/components/atoms";
import { KakaoTalkIcon } from "@earlybird/icons";

/**
 * `SocialLoginButton` 는 소셜 로그인 진행을 위한 버튼 형태의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <SocialLoginButton
 *   type={"KAKAO"}
 *   label={"카카오로 3초만에 시작하기"}
 * />
 * ```
 */
export const SocialLoginButton = forwardRef<
  HTMLElement,
  SocialLoginButtonProps
>(function SocialLoginButton(
  {
    className,
    as,
    onClick,
    type,
    label,
    icon,
    link,
    ...rest
  }: SocialLoginButtonProps,
  forwardedRef: ForwardedRef<HTMLElement>,
) {
  const BaseComponent = as ?? "button";

  const LoginButton = (props: SocialLoginButtonProps) => (
    <BaseComponent
      {...rest}
      ref={forwardedRef}
      className={classNames(
        styles.SocialLoginButton,
        "earlybird-social-login-button",
        className,
      )}
      href={link}
      type={"button"}
      onClick={onClick}
    >
      <Icon
        icon={props.icon}
        size={"md"}
        style={{ position: "absolute", left: 16 }}
        color={"content-primary"}
      />
      <Text
        typo={"body-1"}
        color={"content-primary"}
        style={{ textGlow: 1, textAlign: "center" }}
      >
        {label ? label : "카카오톡 3초 만에 시작하기"}
      </Text>
    </BaseComponent>
  );

  if (type === "KAKAO") {
    return <LoginButton icon={KakaoTalkIcon} {...rest} />;
  }

  return <LoginButton {...rest} />;
});

export default SocialLoginButton;
