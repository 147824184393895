import React, { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import styles from "./DeliveryAccountModal.module.scss";
import { DeliveryAccountModalProps } from "./DeliveryAccountModal.types";
import "@ui/styles/index.scss";
import { ActionButton, PageHeader } from "@ui/components/layouts";
import { Image, Input, VStack } from "@ui/components/atoms";
import { AccountInput } from "@ui/components/organisms/AccountInput";
import { getPlatformImage } from "@ui/components/templates/DeliveryAccountModal/utils";
import { DeliveryCode, DeliveryNames } from "@earlypay/shared/typings";

/**
 * `DeliveryAccountModal` 는 배달앱 계정을 등록 및 수정, 삭제할 수 있는 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <DeliveryAccountModal
 *   visible={true}
 *   onClose={onClose}
 *   platform={"BAEMIN"}
 * />
 * ```
 */
export const DeliveryAccountModal = forwardRef<
  HTMLElement,
  DeliveryAccountModalProps
>(function DeliveryAccountModal(
  {
    className,
    as,
    platform = DeliveryCode.BAEMIN,
    visible,
    onClose,
    loading,
    handleAddDeliveryAccount,
    handleDeleteDeliveryAccount,
    handleEditDeliveryAccount,
    oldId,
    value,
    disabled,
    handleChange,
    ...rest
  }: DeliveryAccountModalProps,
  forwardedRef: ForwardedRef<HTMLElement>,
) {
  const BaseComponent = as ?? "div";

  const oldTitle = oldId && `현재 ${DeliveryNames[platform]} 계정`;
  const title = oldId && `변경할 ${DeliveryNames[platform]} 계정`;

  const handleUpdateAccount = () => {
    if (!!oldId) {
      return handleEditDeliveryAccount(platform);
    }

    handleAddDeliveryAccount(platform);
  };

  if (!visible) {
    return;
  }

  return (
    <BaseComponent
      {...rest}
      ref={forwardedRef}
      className={classNames(
        styles.DeliveryAccountModal,
        "earlybird-delivery-account-modal",
        className,
      )}
    >
      <PageHeader
        buttonType={"mixed"}
        rightActionLabel={"계정 삭제"}
        handleClickRightButton={handleDeleteDeliveryAccount}
        handleClickLeftButton={onClose}
        hasChat={false}
      ></PageHeader>
      <VStack height={"100%"} padding={20} spacing={16} align={"center"}>
        <Image width={200} height={40} src={getPlatformImage(platform)} />

        <VStack spacing={8}>
          {oldId && <Input readonly value={oldId} title={oldTitle} />}

          <AccountInput
            title={title}
            value={value}
            name={{ id: "id", password: "password" }}
            placeholder={{
              id: oldId ? "변경할 아이디 입력" : "아이디 입력",
              password: oldId ? "변경할 비밀번호 입력" : "비밀번호 입력",
            }}
            handleChange={handleChange}
          />
        </VStack>
      </VStack>

      <ActionButton
        type={"button"}
        primaryButtonLabel={oldId ? "변경하기" : "연결하기"}
        onClickPrimaryButton={handleUpdateAccount}
        loading={loading}
        disabledPrimaryButton={disabled}
        primaryButtonProperty={{
          description: oldId
            ? "배달앱 계정 변경 버튼"
            : "배달앱 계정 입력 버튼",
        }}
      />
    </BaseComponent>
  );
});

export default DeliveryAccountModal;
