import React, { useEffect, useState } from "react";

import { getCookie } from "@earlypay/shared";
import {
  PageViewGA,
  bootChannelIO,
  initGA,
  initGTM,
  initKakao,
  initMixpanel,
  initSentry,
  logWelcomeMessage,
  setMixpanelUser,
  setSentryUser,
  updateChannelIOUser,
} from "@earlypay/shared";
import {
  initClarity,
  setClarityUser,
} from "@earlypay/shared/src/configs/analytics/clarity";
import { appStatusState } from "@recoil/appStatus/atoms";
import { applicationState } from "@recoil/application/atom";
import { useLocation } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";

import { useFetchApplication } from "@apis/hooks";

import { checkPWA } from "@utils/checkPWA";

const InitializeServiceRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data: application } = useFetchApplication();
  const accessToken = getCookie("earlypay-token");
  const location = useLocation();
  const [isInitialized, setIsInitialized] = useState(false);
  const [appStatus, setAppStatus] = useRecoilState(appStatusState);
  const setApplication = useSetRecoilState(applicationState);

  useEffect(() => {
    // PWA 설정
    if (!getCookie("checkPWA")) checkPWA();
    const handler = (e: KeyboardEvent): void => {
      setAppStatus({ ...appStatus, promptInstall: e });
    };
    window.addEventListener("beforeinstallprompt", handler);

    // initialize service
    initGA();
    initKakao();
    initGTM();
    initMixpanel();
    initSentry();
    initClarity(import.meta.env.VITE_EARLYPAY_CLARITY_ID);

    bootChannelIO();

    logWelcomeMessage();

    setIsInitialized(true);
  }, []);

  useEffect(() => {
    // tracking event
    if (isInitialized) {
      PageViewGA(location.pathname + location.search);
    }

    // update user data
    if (accessToken && application?.id) {
      setApplication(application);
      updateChannelIOUser(application);
      setSentryUser(application);
      setClarityUser(application);
      setMixpanelUser(application);
    }
  }, [application, isInitialized, location]);

  if (!application.id && accessToken) {
    return null;
  }

  return <>{children}</>;
};

export default InitializeServiceRoute;
