import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { PDFDocument } from "pdf-lib";
import { sentryHook, trackEventMixpanel } from "../configs";

export const changeHtmlToPdf = async (elementId: string, filename?: string) => {
  const inputElement = document.getElementById(elementId);

  if (!inputElement) {
    console.error("inputElement Error");
    return;
  }

  const result: any = await html2canvas(inputElement, {
    allowTaint: true,
    useCORS: true,
    scale: 2,
  })
    .then((canvas) => {
      const image = { type: "jpeg", quality: 0.98 };
      const margin = [0.5, 0.5];

      const imgWidth = 8.5;
      let pageHeight = 11;

      const innerPageWidth = imgWidth - margin[0] * 2;
      const innerPageHeight = pageHeight - margin[1] * 2;

      // Calculate the number of pages.
      const pxFullHeight = canvas.height;
      const pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
      const nPages = Math.ceil(pxFullHeight / pxPageHeight);

      // Define pageHeight separately so it can be trimmed on the final page.
      pageHeight = innerPageHeight;

      // Create a one-page canvas to split up the full image.
      const pageCanvas = document.createElement("canvas");
      const pageCtx = pageCanvas.getContext("2d");
      pageCanvas.width = canvas.width;
      pageCanvas.height = pxPageHeight;

      // Initialize the PDF.
      const pdf = new jsPDF("p", "in", [8.5, 11]);

      for (let page = 0; page < nPages; page++) {
        // Trim the final page to reduce file size.
        if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
          pageCanvas.height = pxFullHeight % pxPageHeight;
          pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
        }

        // Display the page.
        const w = pageCanvas.width;
        const h = pageCanvas.height;
        pageCtx.fillStyle = "white";
        pageCtx.fillRect(0, 0, w, h);
        pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

        // Add the page to the PDF.
        if (page > 0) pdf.addPage();
        const imgData = pageCanvas.toDataURL(
          "image/" + image.type,
          image.quality,
        );
        pdf.addImage(
          imgData,
          image.type,
          margin[1],
          margin[0],
          innerPageWidth,
          pageHeight,
        );
      }

      pdf.setProperties({ title: filename ? filename : elementId });
      const pdfFile: Blob = pdf.output("blob");

      return pdfFile;
    })
    .catch((error) => {
      console.error("Error converting HTML to PDF:", error);

      sentryHook({
        title: "pdf 파일을 생성하는 데에 실패했습니다.",
        error: error,
      });
      throw error;
    });

  return result;
};

export const mergePdfs = async (pdfFiles: Blob[]): Promise<Blob> => {
  try {
    // Create a new PDF document
    const mergedPdf = await PDFDocument.create();

    for (const pdfFile of pdfFiles) {
      // Load the current PDF file
      const pdfBytes = await pdfFile.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);

      // Copy all pages from the loaded PDF to the merged PDF
      const copiedPages = await mergedPdf.copyPages(
        pdfDoc,
        pdfDoc.getPageIndices(),
      );
      copiedPages?.forEach((page) => {
        mergedPdf.addPage(page);
      });
    }

    const mergedPdfBytes = await mergedPdf.save();

    trackEventMixpanel("merge_pdf_file");
    return new Blob([mergedPdfBytes], { type: "application/pdf" });
  } catch (error) {
    console.error("Error merging PDFs:", error);

    sentryHook({
      title: "pdf 파일을 병합하는 데에 실패했습니다.",
      error: error,
    });
    throw error;
  }
};
