import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import MainOldLayout from "@routes/layouts/MainOldLayout";

import { Accordion, Meta } from "@components/commons/index";
// component(base)
import { Header } from "@components/modules/common/index";

// component (styled)
import { PolicyBox, PolicyText, TextBox, TitleBox } from "./index.styled";

const Notice = () => {
  const notice = [
    {
      title: {
        type: "일반공지",
        title: "개인정보처리방침 변경 사항 안내",
        date: "23.12.4",
      },
      body: {
        title: "변경내용",
        content: ``,
      },
    },
    {
      title: {
        type: "이벤트",
        title: "투자유치 기념 친구초대 황금 이벤트",
        date: "23.05.01 ~ 23.05.31",
      },
      body: {
        title: "안녕하세요 얼리페이 입니다.",
        content:
          "얼리페이에서 투자유치 기념으로, 사장님께 감사의 마음을 담아 빛나는 황금 한돈을 드리는 이벤트를 진행하고 있습니다. 자세한 사항은 이벤트 항목을 참고 부탁드리겠습니다.",
      },
    },
    {
      title: {
        type: "이벤트",
        title: "친구추천 이벤트",
        date: "22.08.02 ~ 23.08.30",
      },
      body: {
        title: "안녕하세요 얼리페이 입니다.",
        content:
          "얼리페이에서 친구초대링크를 통해 친구 가입시 가입한 친구와 사장님께 모두 1개월 무료 구독권을 지급해드립니다. 자세한 사항은 이벤트 항목을 참고 부탁드리겠습니다.",
      },
    },
  ];

  return (
    <>
      <Meta title={`얼리페이 | 마이페이지`} name={"공지사항"} />
      <Header _white _backHistory="/my">
        공지사항
      </Header>
      <MainOldLayout _padding>
        <div style={{ margin: "0 -20px" }}>
          {notice.map(x => (
            <Accordion
              key={x.title.title}
              _style={{
                border: "none",
                borderRadius: "0",
                marginBottom: "0",
                borderBottom: "1px solid #eee",
              }}
            >
              <TitleBox>
                <div>{x.title.type}</div>
                <h6>{x.title.title}</h6>
                <span>{x.title.date}</span>
              </TitleBox>
              {x.title.title === "개인정보처리방침 변경 사항 안내" ? (
                <PolicyBox>
                  <PolicyText>
                    개인정보처리방침 및 서비스 약관 개정 안내
                    <br />
                    <br />
                    안녕하세요, 얼리페이입니다. <br />
                    <br />
                    얼리페이 서비스를 이용해 주시는 회원분들께 진심으로
                    감사드립니다. <br />
                    얼리페이의 개인정보처리방침 및 서비스 약관이 개정될
                    예정입니다. 개정 내용을 아래와 같이 안내 드리니, 서비스
                    이용에 참고해 주시기 바랍니다.
                    <br />
                    <br />
                    개정 대상
                    <br />- 개인정보 수집・이용 동의{" "}
                    <a
                      href="https://app.catchsecu.com/document/C/41866f30ae09d7e"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      (링크)
                    </a>
                    <br />- 고유식별정보 수집・이용 동의{" "}
                    <a
                      href="https://app.catchsecu.com/document/C/e488f1e207a04fa"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      (링크)
                    </a>
                    <br />- 주민등록번호 처리 안내{" "}
                    <a
                      href="https://app.catchsecu.com/document/C/e1b2860a02ec350"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      (링크)
                    </a>
                    <br />- 개인정보 제3자제공 동의_한화손해보험{" "}
                    <a
                      href="https://app.catchsecu.com/document/C/695b0d85b9ae5fb"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      (링크)
                    </a>
                    <br />- 개인정보 제3자제공 동의_얼리페이대부{" "}
                    <a
                      href="https://app.catchsecu.com/document/C/95e5865a887f0a5"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      (링크)
                    </a>
                    <br />- 고유식별정보 제3자제공 동의_한화손해보험{" "}
                    <a
                      href="https://app.catchsecu.com/document/C/2dec7ceddb7f001"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      (링크)
                    </a>
                    <br />- 고유식별정보 제3자제공 동의_얼리페이대부{" "}
                    <a
                      href="https://app.catchsecu.com/document/C/cd33f65fd121c27"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      (링크)
                    </a>
                    <br />- 광고성 정보 수신 동의{" "}
                    <a
                      href="https://app.catchsecu.com/document/C/674a3c83f3c530b"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ textDecoration: "none" }}
                    >
                      (링크)
                    </a>
                    <br />
                    <br />
                    변경 일자 <br />
                    1. 고지 일자 : 2023년 12월 4일
                    <br />
                    2. 시행 일자 : 2023년 12월 11일
                    <br />
                    <br />
                    개정 약관에 동의하지 않는 경우 회원 탈퇴를 하실 수 있습니다.
                    <br />
                    개정 약관의 적용일까지 별도의 의사표시가 없는 경우 본 개정
                    약관에 동의하신 것으로 간주됩니다.
                    <br />
                    감사합니다.
                    <br />
                    <br />
                    (주)얼리페이 드림
                  </PolicyText>
                </PolicyBox>
              ) : (
                <TextBox>
                  <h6>{x.body.title}</h6>
                  <div>{x.body.content}</div>
                </TextBox>
              )}
            </Accordion>
          ))}
        </div>
      </MainOldLayout>
    </>
  );
};

export default Notice;
