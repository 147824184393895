import { getCookie, sentryHook } from "@earlypay/shared";

import { errorToast } from "@components/atoms/Toast";

import { getBitlyUrlList, postBitlyShortenUrl } from "./bitlyURL";

/**
 * 친구추천 코드가 등록된 url을 카카오 링크로 공유합니다
 * @param earlypaid 선정산 시작여부
 * @param userName 유저이름
 * @param recommenderCode 친구초대 코드
 * @param setIsLoading Spinner state
 * @returns
 */
export const sendKakaoLink = async (
  earlypaid: boolean,
  userName: string,
  recommenderCode: string,
  setIsLoading: (state: boolean) => void,
) => {
  const accessToken = getCookie("earlypay-token");

  // 선정산 시작 전에는 호출할 수 없습니다
  if (!earlypaid) return errorToast("최초 선정산 이후 가능한 서비스입니다");

  try {
    // 선정산 시작 전에는 호출할 수 없습니다
    // if (!earlypaid) return errorToast("최초 선정산 이후 가능한 서비스입니다");

    // Bitly url을 가져옵니다.
    setIsLoading(true);
    let shortUrl = await getBitlyUrlList(recommenderCode);

    // Bitly url이 없을경우 신규 등록합니다.
    if (!shortUrl) {
      const createURL = await postBitlyShortenUrl(userName, recommenderCode);
      shortUrl = createURL;
    }
    setIsLoading(false);

    window.Kakao.Share.sendDefault({
      objectType: "feed",
      content: {
        title: "얼리페이",
        description: `${userName}사장님이 6만원 상당의 얼리페이 서비스 이용권을 선물하셨어요!`,
        imageUrl: "https://earlypay.kr/icons/invite.png",
        link: {
          mobileWebUrl: shortUrl,
          webUrl: shortUrl,
        },
      },
      buttons: [
        {
          title: "지금 선물 받기",
          link: {
            mobileWebUrl: shortUrl,
            webUrl: shortUrl,
          },
        },
      ],
      installTalk: true,
    });
  } catch (error) {
    console.error(error);

    sentryHook({
      title: "카카오 친구추천 링크 공유에 실패했습니다.",
      error: error,
    });
  }
};
