import React, { useState } from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import MainOldLayout from "@routes/layouts/MainOldLayout";

import { Meta } from "@components/commons/index";
import { Header } from "@components/modules/common/index";
import {
  CalendarCell,
  CalendarDays,
  CalendarHeader,
  SalesTitle,
} from "@components/modules/sales/index";

const Calendar = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [firstDate, setFirstDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Meta title="얼리페이 | 캘린더" name={"정산 캘린더"} />
      <Header _white _backHistory="/">
        캘린더
      </Header>
      <MainOldLayout>
        <SalesTitle _currentMonth={currentMonth} />
        <CalendarHeader
          _currentMonth={currentMonth}
          _setCurrentMonth={setCurrentMonth}
          _isLoading={isLoading}
          _setIsLoading={setIsLoading}
          _firstDate={firstDate}
        />
        <CalendarDays />
        <CalendarCell
          _currentMonth={currentMonth}
          _isLoading={isLoading}
          _firstDate={firstDate}
          _setFirstDate={setFirstDate}
          _setIsLoading={setIsLoading}
        />
      </MainOldLayout>
    </>
  );
};

export default Calendar;
