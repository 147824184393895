import React from "react";

import { ApplicationStatusCode } from "@earlypay/shared";
import { MainLayout } from "@routes/layouts/MainLayout";
import InitializeServiceRoute from "@routes/utils/InitializeServiceRoute";
import ProtectedMainRoute from "@routes/utils/ProtectedMainRoute";
import { Navigate, useLocation } from "react-router-dom";

import { ConfirmModalProvider, ToastProvider } from "@earlybird/ui";

import { useLogin } from "@hooks/useLogin";

import { useFetchApplication } from "@apis/hooks/applications/queries";

import { getCookie } from "@utils/cookie";

const MainRouteGuard: React.FC = () => {
  const location = useLocation();
  const currentQuery = new URLSearchParams(location.search);
  const { getLoginUrlWithNextUrl } = useLogin();
  const accessToken = getCookie("earlypay-token");
  const { data: application } = useFetchApplication();

  /** accessToken 이 없는 경우, 로그인 또는 랜딩 페이지로 이동합니다. */
  if (!accessToken) {
    if (location.pathname === "/") {
      return (
        <Navigate
          to={`/landing?${currentQuery.toString()}`}
          replace
          state={{ from: location }}
        />
      );
    }

    return (
      <Navigate
        to={getLoginUrlWithNextUrl()}
        replace
        state={{ from: location }}
      />
    );
  }

  /** accessToken 이 존재하지만 신청서 작성이 끝나지 않은 경우, 신청서 작성 페이지로 이동합니다. */
  if (
    application.id &&
    application.status !== ApplicationStatusCode.APPROVED &&
    (location.pathname === "/" ||
      location.pathname.includes("my") ||
      location.pathname.includes("calender") ||
      location.pathname.includes("settlement"))
  ) {
    return (
      <Navigate
        to={`/service-apply?${currentQuery.toString()}`}
        replace
        state={{ from: location }}
      />
    );
  }

  if (!application.id) {
    return null;
  }

  if (window.Kakao.isInitialized() && !window.Kakao.Auth.getAccessToken()) {
    window.Kakao.Auth.setAccessToken(accessToken);
  }

  return (
    <ToastProvider>
      <ConfirmModalProvider>
        <InitializeServiceRoute>
          <ProtectedMainRoute>
            <MainLayout />
          </ProtectedMainRoute>
        </InitializeServiceRoute>
      </ConfirmModalProvider>
    </ToastProvider>
  );
};

export default MainRouteGuard;
