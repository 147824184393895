import React, { useEffect } from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import MainOldLayout from "@routes/layouts/MainOldLayout";

import { Meta } from "@components/layouts";
import {
  CountCard,
  EventBanner,
  HomeHeader,
  SaleChart,
  TodayDeopositsCard,
} from "@components/modules/home/index";

import { getCookie } from "@/utils/cookie";

const Home = () => {
  useEffect(() => {
    const inviteEventCookie = getCookie("thanksgiving");
    if (inviteEventCookie === "true") return;
  }, []);

  return (
    <>
      <Meta title="얼리페이 | 홈" name={"홈"} />
      <MainOldLayout _home>
        <div style={{ padding: "0 20px" }}>
          <div style={{ paddingBottom: "20px" }}>
            <HomeHeader />
            <TodayDeopositsCard />
            <EventBanner />
            <SaleChart />
            <CountCard />
          </div>
        </div>
      </MainOldLayout>
    </>
  );
};

export default Home;
