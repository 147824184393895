import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import {
  TrialBottomButtonContainer,
  TrialBottomWrapper,
} from "@pages/landing/Landing/index.styles";

import Button from "@components/atoms/Button";
import { ButtonSizes } from "@components/atoms/Button/type";
import { Meta } from "@components/layouts";
import Container from "@components/layouts/Container";
import Footer from "@components/layouts/Footer";
import LadingHeader from "@components/layouts/LandingHeader";
import {
  AccumulatedAmountSection,
  FastCalculate,
  FeatureSection,
  MainSection,
  PriceSection,
  QNASection,
  ReviewSection,
  TopBanner,
} from "@components/templates/landing";

import useNavigateWithParams from "@hooks/useNavigateParams";

const Landing = () => {
  const { navigateWithParams } = useNavigateWithParams();

  const handleGoToLogin = () => {
    navigateWithParams({
      pathname: `/login`,
      state: { referrer: document.referrer },
    });
  };

  return (
    <>
      <Meta title={"얼리페이"} name={"랜딩페이지"} />
      <Container>
        <TopBanner handleClickTrialButton={handleGoToLogin} />
        <LadingHeader handleClickTrialButton={handleGoToLogin} />
        <MainSection handleClickTrialButton={handleGoToLogin} />
        <AccumulatedAmountSection />
        <FeatureSection />
        <PriceSection />
        <ReviewSection />
        <FastCalculate />
        <QNASection />
        <Footer />
        <TrialBottomWrapper>
          <TrialBottomButtonContainer>
            <Button onClick={handleGoToLogin} size={ButtonSizes.LG}>
              한 달 무료 이용하기
            </Button>
          </TrialBottomButtonContainer>
        </TrialBottomWrapper>
      </Container>
    </>
  );
};

export default Landing;
