import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import styles from "./ImageUploader.module.scss";
import { ImageUploaderProps } from "./ImageUploader.types";
import "@ui/styles/index.scss";
import ImageMethodSelector from "@ui/components/organisms/ImageMethodSelector/ImageMethodSelector";
import { ImageUploadGuide } from "@ui/components/organisms";
import { changeEventToFile, checkMobile } from "@earlypay/shared/utils";
import { trackEventMixpanel } from "@earlypay/shared";

/**
 * `ImageUploader` 는 사진 촬영 또는 불러오기를 실행하고 첨부 가이드를 같이 제공하여 필요한 이미지를 첨부하도록 유도하기 위한 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <ImageUploader
 *   guide="identification"
 *   visible={true}
 *   onClose={onClose}
 *   handleSaveImage={handleSaveImage}
 * />
 * ```
 */
export const ImageUploader = forwardRef<HTMLElement, ImageUploaderProps>(
  function ImageUploader(
    {
      className,
      as,
      visible,
      onClose,
      hasGuide = true,
      guide,
      handleSaveImage,
      handleCamera,
      ...rest
    }: ImageUploaderProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const [method, setMethod] = useState<"camera" | "gallery">("gallery");
    const [loading, setLoading] = useState<boolean>(false);
    const [visibleMethod, setVisibleMethod] = useState<boolean>(false);
    const [visibleGuide, setVisibleGuide] = useState<boolean>(false);

    const BaseComponent = as ?? "div";

    const cameraRef = useRef<HTMLInputElement>();
    const galleryRef = useRef<HTMLInputElement>();

    const handleCloseModal = useCallback(() => {
      onClose();
      setVisibleGuide(false);
    }, [onClose]);

    /** onChange 이벤트를 받아서 선택한 파일을 반환하고, 이미지 업로더 창을 닫습니다.  */
    const handleConvertImage = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      if (e.target.files && e.target.files?.length > 0) {
        setLoading(true);
        const imageFile = changeEventToFile(e);
        handleCloseModal();
        handleSaveImage(imageFile);
      }

      e.target.value = "";
      setLoading(false);

      onClose();
    };

    /** [확인했어요] 버튼을 클릭하고, 첨부할 이미지를 선택합니다.
     * 1. 촬영일 경우, 디바이스의 내장된 카메라가 나타납니다.
     * 2. 불러오기일 경우, 디바이스의 파일 선택 또는 윈도우에서 파일 첨부 창이 나타납니다.
     */
    const handleSelectImageFile = useCallback(() => {
      if (method === "camera") {
        if (handleCamera) {
          handleCloseModal();
          handleCamera();
        }
        cameraRef.current.value = "";
        return cameraRef.current.click();
      }

      galleryRef.current.value = "";
      galleryRef.current.click();
      onClose();
    }, [method, onClose, handleCamera, handleCloseModal]);

    const handleClickMethod = (method: "camera" | "gallery") => {
      if (!hasGuide) {
        onClose();
        return handleSelectImageFile();
      }

      method === "camera"
        ? trackEventMixpanel("select_image_method_camera", {
            description: "사진 불러오기",
          })
        : trackEventMixpanel("select_image_method_gallery", {
            description: "사진 촬영",
          });

      setMethod(method);
      setVisibleGuide(true);
      onClose();
    };

    /** 모바일일 경우에만 이미지 첨부 방법을 선택합니다.
     * 웹일 경우, ImageMethodSelector 가 나타나지 않고 무조건 사진 불러오기로 진행합니다.
     */
    useEffect(() => {
      if (visible && checkMobile() === "window") {
        if (!hasGuide) {
          return handleSelectImageFile();
        }

        setVisibleMethod(false);
        setVisibleGuide(true);
        return;
      }

      setVisibleMethod(visible);
    }, [visible, hasGuide, handleSelectImageFile]);

    return (
      <BaseComponent
        {...rest}
        ref={forwardedRef}
        className={classNames(
          styles.ImageUploader,
          "earlybird-image-uploader",
          className,
        )}
      >
        <input
          ref={cameraRef}
          type="file"
          accept="image/jpg, image/jpeg, image/png"
          capture="environment"
          className={classNames(styles.File)}
          onChange={handleConvertImage}
        />
        <input
          ref={galleryRef}
          type="file"
          accept="image/jpg, image/jpeg, image/png"
          className={classNames(styles.File)}
          onChange={handleConvertImage}
        />

        {/** 이미지 첨부 방법 선택 */}
        <ImageMethodSelector
          visible={visibleMethod}
          onClose={onClose}
          handleClickMethod={handleClickMethod}
        />

        {/** 이미지 첨부 가이드 */}
        {visibleGuide && (
          <ImageUploadGuide
            visible={visibleGuide}
            onClose={() => {
              onClose();
              setVisibleGuide(false);
            }}
            handleClickImageButton={handleSelectImageFile}
            guide={guide}
            loading={loading}
          />
        )}
      </BaseComponent>
    );
  },
);

export default ImageUploader;
