import React, { useEffect } from "react";

import {
  sentryHook,
  trackPageViewMixpanel,
  withdrawalAccountSchema,
} from "@earlypay/shared";
import { yupResolver } from "@hookform/resolvers/yup";
import { floatingContentMotion } from "@styles/animations";
import { FormProvider, useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { ActionButton, PageContainer, Text } from "@earlybird/ui";

import Stack from "@components/atoms/Stack";
import { Header, Meta } from "@components/layouts";
import LicenseVerifyForm from "@components/templates/serviceApply/LicenseVerifyForm";

import useNavigateWithParams from "@hooks/useNavigateParams";

import { useVerifyBusinessLicense } from "@apis/hooks/document/mutations";
import { useFetchBusinessLicenseOcr } from "@apis/hooks/document/queries";

import { applicationState } from "@/recoil/application/atom";

export const CheckLicenseInfo: React.FC = () => {
  const { navigateWithParams } = useNavigateWithParams();
  const application = useRecoilValue(applicationState);
  const { data: businessLicense } = useFetchBusinessLicenseOcr(application.id);
  const mutation = useVerifyBusinessLicense(application.id);
  const methods = useForm({
    resolver: yupResolver(withdrawalAccountSchema),
  });

  const onSubmit = () => {
    mutation.mutate();
  };

  const handleReloadLicenseImage = () => {
    navigateWithParams({ pathname: "/service-apply/license-verify" });
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      navigateWithParams({ pathname: "/service-apply/license-verify/verify" });
    }
  }, [mutation.isSuccess]);

  return (
    <>
      <Meta
        title={`얼리페이 | 서비스 신청`}
        name={"사업자등록증 인증 | 사용자 확인"}
      />
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Header
            previous={
              application.user.isCertified
                ? "/service-apply/license-verify/upload"
                : "/service-apply/auth"
            }
          ></Header>

          <PageContainer spacing={20}>
            <Stack spacing={6}>
              <Text typo={"subtitle-1"} bold>
                {"아래 정보가 정확한가요?"}
              </Text>
              <Text color={"content-secondary"}>
                {
                  "추출된 정보가 틀린 경우 사업자등록증을\n다시 촬영해 첨부해주세요."
                }
              </Text>
            </Stack>

            <LicenseVerifyForm data={businessLicense} progress={"CHECK"} />
          </PageContainer>

          <ActionButton
            buttonType={"duo-horizontal"}
            primaryButtonLabel={"모두 맞아요"}
            onClickSecondaryButton={handleReloadLicenseImage}
            secondaryButtonLabel={"다시 첨부하기"}
            loading={mutation.isPending}
            primaryButtonProperty={{
              description: "사업자등록증 사용자 정보 확인 버튼",
            }}
          />
        </Form>
      </FormProvider>
    </>
  );
};

export default CheckLicenseInfo;

const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${floatingContentMotion}
`;
