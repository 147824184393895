import React from "react";

import {
  DepositorCode,
  DepositorNames,
  TotalSalesTypes,
} from "@earlypay/shared/typings";
import { changeCodeToPath } from "@pages/settlement/utils";

import BottomSheet from "@components/molecules/BottomSheet";
import { ButtonQty } from "@components/molecules/BottomSheet/type";
import BillingInfoMessage from "@components/templates/settlement/BillingInfoMessage";
import { BillingInfoMessageType } from "@components/templates/settlement/BillingInfoMessage/types";

import useNavigateWithParams from "@hooks/useNavigateParams";
import { useSettlement } from "@hooks/useSettlement";

import { SalesAmountList } from "../../SettlementAmountList";

interface DeliverySalesBottomSheetProps {
  depositor: DepositorCode;
  data?: TotalSalesTypes;
  isLoading: boolean;
}

/** 배달 별 총 매출 내역을 BottomSheet 에서 보여줍니다. */
export const DeliverySalesBottomSheet: React.FC<
  DeliverySalesBottomSheetProps
> = ({ depositor, data, isLoading }) => {
  const { handleCloseBillingBottomSheet } = useSettlement();
  const { navigateWithParams } = useNavigateWithParams();
  const { startDateParams, endDateParams } = useSettlement();

  const depositorName = DepositorNames[depositor];
  const type = depositor === DepositorCode.CARD ? "card" : "delivery";

  /** [자세히보기] 버튼을 누르면 건별 상세 내역으로 이동합니다. */
  const handleGoToHistory = () => {
    navigateWithParams({
      pathname: "/settlement/history",
      query: {
        startDate: startDateParams,
        endDate: endDateParams,
        type: type,
        delivery:
          depositor === DepositorCode.CARD
            ? "all"
            : changeCodeToPath(depositor),
      },
      state: {
        from: `/settlement/billing?startDate=${startDateParams}&endDate=${endDateParams}&depositor=${changeCodeToPath(
          depositor,
        )}&type=${type}`,
      },
    });
  };

  return (
    <BottomSheet
      onClose={handleCloseBillingBottomSheet}
      headerText={`${depositorName} 총 매출`}
      buttonQty={ButtonQty.DUO_HORIZONTAL}
      secondaryButtonText={"닫기"}
      primaryButtonText={"자세히 보기"}
      onClickPrimaryButton={handleGoToHistory}
    >
      {(depositor === DepositorCode.BAEMIN ||
        depositor === DepositorCode.YOGIYO) && (
        <BillingInfoMessage type={BillingInfoMessageType.DELIVERY_SALES} />
      )}
      <SalesAmountList list={data} isLoading={isLoading} />
    </BottomSheet>
  );
};

export default DeliverySalesBottomSheet;
