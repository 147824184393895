import { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import styles from "./PageHeader.module.scss";
import { PageHeaderProps } from "./PageHeader.types";
import "@ui/styles/index.scss";
import { ArrowLeftIcon, WheelIcon } from "@earlybird/icons";
import { Text, Box, IconButton, HStack } from "@ui/components/atoms";
import { ChatButton } from "@ui/components/molecules";
import { PAGE_HEADER_HEIGHT } from "@ui/common/styles";
import { getPixelValue } from "@ui/utils/types";
import {
  openChannelIOChat,
  trackEventMixpanel,
} from "@earlypay/shared/configs";

/**
 * `PageHeader` 는 다양한 레이아웃과 스타일 속성을 쉽게 적용하기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <PageHeader
 *   buttonType={"icon"}
 *   onBack={handleGoToBack}
 * >
 * 타이틀
 * </PageHeader>
 * ```
 */
export const PageHeader = forwardRef<HTMLElement, PageHeaderProps>(
  function PageHeader(
    {
      className,
      as,
      buttonType = "icon",
      children,
      leftButtonIcon = ArrowLeftIcon,
      rightButtonIcon = WheelIcon,
      onBack,
      leftActionLabel,
      hasLeftButton = true,
      handleClickLeftButton,
      rightActionLabel,
      hasRightButton = true,
      handleClickRightButton,
      handleClickChatButton = openChannelIOChat,
      hasChat = true,
      style,
      ...rest
    }: PageHeaderProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const BaseComponent = as ?? "div";
    const defaultStyle = {
      "--header-height": getPixelValue(PAGE_HEADER_HEIGHT),
    };

    const handleClickLeftBox = () => {
      if (handleClickLeftButton) {
        return handleClickLeftButton();
      }
    };

    const handleOpenChat = () => {
      trackEventMixpanel("click_chat_button", {
        description: "채널톡 상담 버튼 클릭",
      });
      handleClickChatButton();
    };

    const LeftContent = () => {
      return (
        <Box
          display={"flex"}
          className={classNames(
            "earlybird-page-header-left-box",
            styles.LeftContent,
          )}
        >
          {hasLeftButton && (
            <>
              {buttonType === "action" ? (
                <Box padding={"4px 12px"}>
                  <Text>{leftActionLabel}</Text>
                </Box>
              ) : (
                <IconButton
                  icon={leftButtonIcon}
                  size={"md"}
                  onClick={handleClickLeftBox}
                />
              )}
            </>
          )}
        </Box>
      );
    };

    const CenterContent = () => {
      return (
        <Box
          className={classNames("earlybird-page-header-title", styles.Center)}
          center
        >
          {children && <Text typo={"body-2"}>{children}</Text>}
        </Box>
      );
    };

    const RightContent = () => {
      return (
        <Box
          className={classNames(
            "earlybird-page-header-right-box",
            styles.RightContent,
          )}
          display={"flex"}
        >
          {hasChat && <ChatButton onClick={handleOpenChat} />}
          {hasRightButton && (
            <>
              {buttonType !== "icon" ? (
                <button
                  onClick={handleClickRightButton}
                  className={classNames(styles.Action)}
                >
                  <Text>{rightActionLabel}</Text>
                </button>
              ) : (
                <IconButton
                  icon={rightButtonIcon}
                  size={"md"}
                  onClick={handleClickRightButton}
                />
              )}
            </>
          )}
        </Box>
      );
    };

    return (
      <BaseComponent
        ref={forwardedRef}
        className={classNames(
          styles.PageHeader,
          "earlybird-page-header",
          className,
        )}
        style={{ ...style, ...defaultStyle }}
        {...rest}
      >
        <HStack justify={"between"} align={"center"} padding={"0 6px"}>
          <LeftContent />
          <CenterContent />
          <RightContent />
        </HStack>
      </BaseComponent>
    );
  },
);

export default PageHeader;
