/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import MainOldLayout from "@routes/layouts/MainOldLayout";
import theme from "@styles/theme";

import { FlexBox, Meta, Span } from "@components/commons/index";
import { Header } from "@components/modules/common/index";

import { useFetchApplication } from "@apis/hooks";

import { useAuth } from "@/hooks/useAuth";

import { AuthButton, InfoGirdBox, InfoText } from "./index.styled";

const Info = () => {
  const { data: application } = useFetchApplication();
  const { handleCertificate } = useAuth("/my");

  // 정산타입
  const earlypayType: any = {
    DE: "배달",
    CA: "카드",
    AL: "카드+배달",
  };

  const userInfo = [
    {
      title: "이름",
      text: application.user.name ?? "알수없음",
    },
    {
      title: "전화번호",
      text: application.user.phone ?? "알수없음",
    },
    {
      title: "정산타입",
      text: earlypayType[application.serviceType] ?? "알수없음",
    },
  ];

  // ---------------------------------- 페이지 렌더링 ---------------------------------
  return (
    <>
      <Meta title={`얼리페이 | 마이페이지`} name={"내 정보 변경"} />
      <Header _white _backHistory="/my">
        내 정보
      </Header>
      <MainOldLayout _padding>
        {userInfo.map(x => (
          <InfoGirdBox _row key={x.title}>
            <FlexBox>
              <Span _style={{ fontSize: "13px", color: theme.color.gray_30 }}>
                {x.title}
              </Span>
              <FlexBox _row _align _style={{ justifyContent: "space-between" }}>
                <InfoText _medium>{x.text}</InfoText>
                {x.title === "이름" && (
                  <AuthButton _onClick={handleCertificate}>
                    <Span _style={{ fontSize: "13px", color: "#fff" }}>
                      재인증
                    </Span>
                  </AuthButton>
                )}
              </FlexBox>
            </FlexBox>
          </InfoGirdBox>
        ))}
      </MainOldLayout>
    </>
  );
};

export default Info;
