import React, { createContext, useState } from "react";
import { ConfirmModal } from "./ConfirmModal";
import { ConfirmModalOptionsType } from "./ConfirmModal.types";
import { ConfirmModalMaps } from "@ui/components/atoms/ConfirmModal/ConfirmModal.maps";
import { trackEventMixpanel } from "@earlypay/shared";

export interface ConfirmModalContextType {
  openConfirmModal: (options: ConfirmModalOptionsType) => void;
  closeConfirmModal: () => void;
}
export interface ConfirmModalVisibleContextType {
  visibleConfirmModal: boolean;
}

export const ConfirmModalContext = createContext<
  ConfirmModalContextType | undefined
>(undefined);

export const ConfirmModalVisibleContext = createContext<
  ConfirmModalVisibleContextType | undefined
>(undefined);

export const ConfirmModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [confirmModal, setConfirmModal] = useState({});
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);

  const openConfirmModal = (options: ConfirmModalOptionsType) => {
    const modal = ConfirmModalMaps.filter(
      (item: ConfirmModalOptionsType) => item.id === options.id,
    );
    trackEventMixpanel("open_confirm_modal", {
      id: options.id,
      title: modal[0]?.title,
      description: "컨펌 모달 노출",
    });
    setConfirmModal({ ...modal[0], ...options });
    setVisibleConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setVisibleConfirmModal(false);
  };

  return (
    <ConfirmModalContext.Provider
      value={{ openConfirmModal, closeConfirmModal }}
    >
      <ConfirmModalVisibleContext.Provider value={{ visibleConfirmModal }}>
        {children}
        <ConfirmModal {...confirmModal} />
      </ConfirmModalVisibleContext.Provider>
    </ConfirmModalContext.Provider>
  );
};
