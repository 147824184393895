import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import styled from "styled-components";

import { Meta } from "@components/commons";
import SettlementHeader from "@components/layouts/SettlementHeader";
import EarlySettlementBoard from "@components/templates/settlement/EarlySettlementBoard";
import SettlementDatePicker from "@components/templates/settlement/SettlementDatePicker";

export const Settlement: React.FC = () => {
  return (
    <>
      <Meta title="얼리페이 | 정산내역" name={"정산내역 요약"} />
      <Container maxWidth={500}>
        <SettlementHeader />
        <Wrapper>
          <InnerWrapper>
            {/** 정산 내역 날짜 조회 버튼 */}
            <SettlementDatePicker />

            {/** 선정산 요약 정보 */}
            <EarlySettlementBoard />
          </InnerWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

export default Settlement;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  overflow: scroll;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  background: #f4f4f4;
  padding: 20px;
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  gap: 12px;
`;
