import React from "react";

import { TERMS_PRIVACY_POLICY } from "@earlypay/shared";
import MainOldLayout from "@routes/layouts/MainOldLayout";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import Icon from "@components/atoms/Icon";
import { IconTags } from "@components/atoms/Icon/type";
import { Meta, Span, Text } from "@components/commons/index";
import { Header } from "@components/modules/common/index";

import { IMG_URL } from "@/@types/commonType";
import { useLogin } from "@/hooks/useLogin";
import { appStatusState } from "@/recoil/appStatus/atoms";

import {
  ImageBanner,
  IndexButton,
  IndexTitle,
  PrivacyPolicyButton,
} from "./index.styled";

interface IndexButtonInterface {
  type: "title" | "button" | "banner";
  label: string;
  route: string | null;
}

const MyIndex = () => {
  const indexNavigations: IndexButtonInterface[] = [
    { type: "title", label: "내 정보", route: null },
    { type: "button", label: "내 정보 변경", route: "info" },
    { type: "button", label: "플랫폼 연동 현황", route: "connect" },
    { type: "button", label: "친구 추천 현황", route: "invite" },
    { type: "banner", label: "친구 추천 현황", route: "invite" },
    { type: "title", label: "서비스이용", route: null },
    { type: "button", label: "이벤트", route: "event" },
    { type: "button", label: "공지사항", route: "notice" },
    { type: "button", label: "자주 묻는 질문", route: "faq" },
    { type: "button", label: "APP 다운로드", route: "down" },
    { type: "button", label: "로그아웃", route: "/" },
  ];

  // ---------------------------------- 페이지 렌더링 ---------------------------------
  return (
    <>
      <Meta title="얼리페이 | 마이페이지" />
      <Header _white _backHistory="/">
        마이 페이지
      </Header>
      <MainOldLayout>
        {indexNavigations.map((nav, idx) => {
          return <MyIndexButton key={idx} _nav={nav} />;
        })}
        <PrivacyPolicyButton
          rel="noreferrer noopener"
          href={TERMS_PRIVACY_POLICY}
          target="_blank"
        >
          <Text _style={{ fontSize: 18, fontWeight: 700 }}>
            개인정보처리방침
          </Text>
        </PrivacyPolicyButton>
      </MainOldLayout>
    </>
  );
};

export default MyIndex;

// ---------------------------------- 추가 컴포넌트 ---------------------------------
const MyIndexButton = ({ _nav }: { _nav: IndexButtonInterface }) => {
  const navigate = useNavigate();
  const { handleLogout } = useLogin();
  const [appStatus, setAppStatus] = useRecoilState(appStatusState);

  const promptInstall = appStatus.promptInstall;

  const appDownload = () => {
    if (promptInstall) {
      promptInstall();
    } else {
      return;
    }
  };

  const navigationButtonHandler = (route: string) => {
    if (route === "/" || _nav.label === "로그아웃") return handleLogout();
    if (route === "down") return appDownload();
    navigate(route);
  };

  if (_nav.label === "APP 다운로드" && !promptInstall) return;

  return (
    <>
      <IndexButton
        key={_nav.label}
        _type={_nav.type}
        _disabled={_nav.type === "title"}
        _onClick={() => navigationButtonHandler(_nav.route)}
      >
        {_nav.type === "title" && <IndexTitle>{_nav.label}</IndexTitle>}
        {_nav.type === "button" && <Span>{_nav.label}</Span>}
        {_nav.type === "banner" && (
          <ImageBanner src={IMG_URL.home.inviteBanner2} />
        )}
        {_nav.type === "button" && <Icon tag={IconTags.CHEVRON_RIGHT} />}
      </IndexButton>
    </>
  );
};
