import { instance } from "@apis/instance";

/**
 * 얼리페이 신청서 작성 단계에서 필요한 문서들을 저장하는 API 함수입니다.
 * @param applicationId
 * @param payload
 */
export const postApplicationDocument = async (
  applicationId: number,
  payload: FormData,
) => {
  const { data } = await instance({
    method: "post",
    url: `/v2/applications/${applicationId}/document`,
    data: payload,
  });

  return data;
};

/*
 * Van 사 등록을 진행하는 API 함수입니다.
 */
export const postVanAccounts = async (payload: object) => {
  const { data } = await instance({
    method: "post",
    url: `v1/applications/platform-accounts/`,
    data: {
      ...payload,
    },
  });

  return data;
};

export const getApplication = async () => {
  const { data } = await instance({
    method: "get",
    url: `/v2/applications/me`,
  });

  return data;
};

/**
 * 사업자등록정보 OCR 추출하여 캐싱된 데이터를 가져오는 API 호출 함수입니다.
 * 캐싱된 데이터는 3일 이후로 사라집니다.
 * @param applicationId
 * @param params
 */
export const getBusinessLicenseOcr = async (
  applicationId: number,
  params: { applicationId: number },
) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/applications/${applicationId}/document/business-registration/ocr`,
    params: { ...params },
  });

  return data;
};

/**
 * 사업자등록정보 OCR 검증이 완료된 정보를 가져오는 API 호출 함수입니다.
 * @param applicationId
 * @param params
 */
export const getBusinessLicense = async (
  applicationId: number,
  params: { applicationId: number },
) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/applications/${applicationId}/document/business-registration`,
    params: { ...params },
  });

  return data;
};

/**
 * 사업자등록증 정보를 검증하는 API 호출 함수입니다.
 * @param applicationId
 */
export const postVerifyBusinessLicense = async <T>(
  applicationId: number,
): Promise<T | null> => {
  const { data } = await instance({
    method: "post",
    url: `/v2/applications/${applicationId}/document/business-registration/verify`,
  });

  return data;
};

/**
 * 사업자등록증 이미지를 전달하여 OCR 인증을 진행하여 정보를 추출하는 API 호출 함수입니다.
 * @param payload
 * @param applicationId
 */
export const postMaskingBusinessLicense = async <T>(
  applicationId: number,
  payload: FormData,
): Promise<T | null> => {
  const { data } = await instance({
    method: "post",
    url: `/v2/applications/${applicationId}/document/business-registration/ocr`,
    data: payload,
  });

  return data;
};
