import axios from "axios";

/**
 * bitly tag가 recommenderCode인 url을 가져옵니다.
 * @param recommenderCode 유저의 친구추천 코드
 * @returns Bitly Shorten URL (ex:https://bit.ly/3H9DVtD)
 */
export const getBitlyUrlList = async (recommenderCode: string) => {
  try {
    const { data } = await axios({
      url: "https://api-ssl.bitly.com/v4/groups/Bl9g7xAevXb/bitlinks",
      timeout: 200000,
      headers: {
        authorization: `Bearer ${import.meta.env.VITE_BITLY_TOKEN}`,
      },
      method: "get",
      params: {
        size: 100,
        page: 1,
        tags: `${recommenderCode}`,
      },
    });

    // 현재 사용중인 bilty url만 가져옵니다.
    const usingBitlyUrl = data?.links.filter((x: { is_deleted?: boolean }) => {
      if (!x.is_deleted) return x;
    })[0]?.link;

    return usingBitlyUrl ?? null;
  } catch (err) {
    new Error(err);
    return `https://earlypay.kr/landing?utm_source=invite&utm_medium=${recommenderCode}`;
  }
};

/**
 * Bitly short url을 등록합니다
 * @param userName 유저 이름
 * @param recommenderCode 유저의 친구추천 코드
 * @returns Bitly Shorten URL (ex:https://bit.ly/3H9DVtD)
 */
export const postBitlyShortenUrl = async (
  userName: string,
  recommenderCode: string,
) => {
  try {
    const { data } = await axios({
      url: "https://api-ssl.bitly.com/v4/bitlinks",
      timeout: 20000,
      headers: {
        authorization: `Bearer ${import.meta.env.VITE_BITLY_TOKEN}`,
      },
      method: "post",
      data: {
        long_url: `${
          import.meta.env.VITE_EARLYPAY_BASE_URL
        }/landing?utm_source=invite&utm_medium=${recommenderCode}`,
        domain: "bit.ly",
        group_guid: "Bl9g7xAevXb",
        title: `${userName}_${recommenderCode}`,
        tags: ["invite", recommenderCode],
      },
    });

    return data?.link;
  } catch (err) {
    new Error(err);
    return `https://earlypay.kr/landing?utm_source=invite&utm_medium=${recommenderCode}`;
  }
};
