import React, { useEffect } from "react";

import {
  bootChannelIO,
  initKakao,
  initMixpanel,
  initSentry,
  logWelcomeMessage,
} from "@earlypay/shared";
import { Outlet } from "react-router-dom";

import { ConfirmModalProvider, ToastProvider } from "@earlybird/ui";

const PublicRouteGuard: React.FC = () => {
  useEffect(() => {
    // initialize service
    initKakao();
    initMixpanel();
    initSentry();
    bootChannelIO();

    logWelcomeMessage();
  }, []);

  return (
    <ToastProvider>
      <ConfirmModalProvider>
        <Outlet />
      </ConfirmModalProvider>
    </ToastProvider>
  );
};

export default PublicRouteGuard;
