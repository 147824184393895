import { ForwardedRef, forwardRef, useContext } from "react";
import classNames from "classnames";
import styles from "./ConfirmModal.module.scss";
import { ConfirmModalProps } from "./ConfirmModal.types";
import "@ui/styles/index.scss";
import {
  ConfirmModalContext,
  ConfirmModalVisibleContext,
} from "@ui/components/atoms/ConfirmModal/ConfirmModal.context";
import { useWindowSize } from "@earlypay/shared/hooks";
import { getPixelValue, noop } from "@ui/utils/types";
import {
  Text,
  BoxButton,
  VStack,
  Icon,
  Stack,
  Box,
} from "@ui/components/atoms";
import { TriangleExclamationMarkSolidIcon } from "@earlybird/icons";

/**
 * `ConfirmModal` 는 사용자의 결정에 대해 경고를 하거나 중요한 정보를 전달하기 위한 디자인 시스템의 컴포넌트입니다.
 * @example
 *
 * ```tsx
 * <ConfirmModal
 *   state="warning"
 *   title="타이틀"
 *   text="텍스트입니다."
 *   primaryButtonLabel="확인"
 *   onClickPrimaryButton={handleClickPrimaryButton}
 * />
 * ```
 */
export const ConfirmModal = forwardRef<HTMLElement, ConfirmModalProps>(
  function ConfirmModal(
    {
      className,
      as,
      id,
      title,
      state = "error",
      contentItem = "text",
      text,
      image,
      info,
      primaryButtonLabel,
      onClickPrimaryButton = noop,
      secondaryButtonLabel,
      onClickSecondaryButton = noop,
      buttonType,
      ...rest
    }: ConfirmModalProps,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const windowSize = useWindowSize();
    const confirmModal = useConfirmModal();

    const Overlay = "div";
    const BaseComponent = as ?? "div";

    const h = getPixelValue(windowSize.height);

    const handleClickPrimaryButton = () => {
      onClickPrimaryButton();
      confirmModal.closeConfirmModal();
    };

    const handleClickSecondaryButton = () => {
      onClickSecondaryButton();
      confirmModal.closeConfirmModal();
    };

    const RenderedContents = () => {
      if (contentItem === "image") {
        return;
      }

      if (contentItem === "info") {
        return (
          <Box radius={"xl"} bg={"bg-secondary"} width={"100%"} padding="12px">
            <VStack as={"ul"} padding="0 0 0 26px">
              {info.map((label, index) => (
                <Text
                  key={index}
                  type={"body-3"}
                  color={"content-secondary"}
                  as={"li"}
                >
                  {label}
                </Text>
              ))}
            </VStack>
          </Box>
        );
      }

      if (contentItem === "text" && !!text) {
        return <Text center>{text}</Text>;
      }

      return null;
    };

    const RenderedButton = () => {
      if (state !== "warning" || buttonType === "duo-vertical") {
        return (
          <Stack
            direction={
              buttonType === "duo-vertical" ? "vertical" : "horizontal"
            }
            reverse={buttonType === "duo-vertical"}
            spacing={4}
          >
            <BoxButton
              onClick={handleClickSecondaryButton}
              size={"lg"}
              hierarchy={"tertiary"}
              property={{ description: `[modal] ${title}` }}
            >
              {secondaryButtonLabel}
            </BoxButton>
            <BoxButton
              onClick={handleClickPrimaryButton}
              size={"lg"}
              hierarchy={state === "confirm" ? "delete" : "primary"}
              property={{ description: `[modal] ${title}` }}
            >
              {primaryButtonLabel}
            </BoxButton>
          </Stack>
        );
      }

      return (
        <BoxButton
          size={"lg"}
          onClick={handleClickPrimaryButton}
          property={{ description: `[modal] ${title}` }}
        >
          {primaryButtonLabel}
        </BoxButton>
      );
    };

    return (
      <>
        <Overlay
          className={classNames(
            styles.Overlay,
            !!confirmModal.visible ? styles["visible"] : styles["hidden"],
            "earlybird-overlay",
          )}
          style={{ height: h }}
        />
        <BaseComponent
          {...rest}
          ref={forwardedRef}
          className={classNames(
            styles.ConfirmModal,
            !!confirmModal.visible ? styles["visible"] : styles["hidden"],
            "earlybird-confirm-modal",
            className,
          )}
        >
          <VStack spacing={10}>
            <VStack spacing={4} align={"center"}>
              {state === "warning" && (
                <Icon
                  icon={TriangleExclamationMarkSolidIcon}
                  color={"content-warning"}
                  size={"lg"}
                />
              )}

              <Text typo={"subtitle-2"} bold center>
                {title}
              </Text>
              <RenderedContents />
            </VStack>

            <RenderedButton />
          </VStack>
        </BaseComponent>
      </>
    );
  },
);

export const useConfirmModal = () => {
  const context = useContext(ConfirmModalContext);
  const visibleContext = useContext(ConfirmModalVisibleContext);

  if (!context || !visibleContext) {
    throw new Error(
      "useConfirmModal must be used within a ConfirmModalProvider",
    );
  }
  return { ...context, visible: visibleContext?.visibleConfirmModal };
};

export default ConfirmModal;
