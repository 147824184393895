import React from "react";

import {
  CardConnectTypes,
  DepositorCode,
  EarlypaysSummaryItemsTypes,
} from "@earlypay/shared/typings";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Icon from "@components/atoms/Icon";
import { IconSizes, IconTags } from "@components/atoms/Icon/type";
import Image from "@components/atoms/Image";
import { ImageTypes } from "@components/atoms/Image/type";
import Interaction from "@components/atoms/Interaction";
import Text from "@components/atoms/Text";
import EarlySettlementBox from "@components/templates/settlement/EarlySettlementInfoItem";

import useSettlement from "@hooks/useSettlement";

import {
  useFetchApplication,
  useFetchCardConnect,
  useFetchEarlypaysSummary,
} from "@apis/hooks";

import { changeNumberToMoney } from "@utils/types";

import { ColorsTypes, FontTypes } from "@/@types/ThemesType";

export const EarlySettlementBoard: React.FC = () => {
  const navigate = useNavigate();
  const { data: application } = useFetchApplication();
  const { currentUrl, startDateParams, endDateParams } = useSettlement();
  const { data: cardConnect } = useFetchCardConnect();
  const { data: earlypaysSummary, isLoading } = useFetchEarlypaysSummary({
    startDate: startDateParams,
    endDate: endDateParams,
  });

  const notConnectedCardCount = cardConnect?.filter(
    (obj: CardConnectTypes) => obj.isConnected === false,
  ).length;
  const isNotConnectedCard = notConnectedCardCount > 0;
  const onClickEmotionLink = (event: React.MouseEvent, url: string) => {
    event.stopPropagation();
    navigate(url);
  };

  return (
    <>
      <Wrapper>
        <TitleWrapper>
          <Text
            tag={FontTypes.BODY_2}
            color={ColorsTypes.CONTENT_SECONDARY}
            bold
          >
            총 지급 금액
          </Text>
          <Text tag={FontTypes.SUBTITLE_1} bold>
            {isLoading ? (
              <Skeleton height={26} width={120} />
            ) : (
              changeNumberToMoney(earlypaysSummary?.totalAmount || 0)
            )}
          </Text>
        </TitleWrapper>

        {/** 미연동 카드사가 하나 이상 존재할 경우, 미연동 안내 메세지를 보여줍니다. */}
        {isNotConnectedCard && (
          <MessageWrapper>
            <MessageIconBox>
              <Icon
                size={IconSizes.XS}
                tag={IconTags.WARNING_FILL}
                color={ColorsTypes.CONTENT_NEGATIVE}
              />
            </MessageIconBox>
            <Text
              tag={FontTypes.BODY_3}
              bold
              color={ColorsTypes.CONTENT_NEGATIVE}
            >
              미연동 카드사가 {notConnectedCardCount}곳이 존재하므로 정산이
              정확하지 않을 수 있어요.
            </Text>
          </MessageWrapper>
        )}

        <Divider />

        <EarlySettlementWrapper>
          {/** 로딩 중일 때 스켈레톤을 보여줍니다. */}
          {isLoading &&
            Object.values(DepositorCode).map((label, index) => {
              return (
                <EarlySettlementBox
                  key={index}
                  depositor={label}
                  value={0}
                  isLoading={isLoading}
                />
              );
            })}

          {!isLoading &&
            earlypaysSummary.items.map(
              (item: EarlypaysSummaryItemsTypes, index: number) => {
                const depositor = item.depositor as DepositorCode;
                const amount = item.amount;

                return (
                  <EarlySettlementBox
                    key={index}
                    depositor={depositor}
                    value={amount}
                    isLoading={isLoading}
                  />
                );
              },
            )}
        </EarlySettlementWrapper>

        <Divider />

        <HistoryList>
          <Interaction>
            <HistoryLink
              to={`/settlement/history?startDate=${startDateParams}&endDate=${endDateParams}&type=all`}
              state={{ from: currentUrl }}
            >
              <Text
                tag={FontTypes.BODY_2}
                color={ColorsTypes.CONTENT_HIGHLIGHT}
                bold
              >
                건별 상세내역 보기
              </Text>
              <Icon
                tag={IconTags.CHEVRON_RIGHT}
                color={ColorsTypes.CONTENT_HIGHLIGHT}
              />
            </HistoryLink>
          </Interaction>
        </HistoryList>
      </Wrapper>
      <VocWrapper onClick={() => navigate("/my/feedback")}>
        <TitleWrapper>
          <Text tag={FontTypes.BODY_1} center bold>
            {application.user.name} 사장님, 얼리페이
            <br />
            만족스럽게 사용 중이신가요?
          </Text>
        </TitleWrapper>
        <EmotionWrapper>
          <Emotion
            onClick={(event: React.MouseEvent) =>
              onClickEmotionLink(event, "/my/feedback?emotion=unsatisfying")
            }
          >
            <Image
              tag={ImageTypes.UNSATISFYING}
              width={40}
              height={40}
              alt="UNSATISFYING"
            />
            <Text tag={FontTypes.BODY_3} color="CONTENT_SECONDARY" center>
              불만족
            </Text>
          </Emotion>
          <Emotion
            onClick={(event: React.MouseEvent) =>
              onClickEmotionLink(event, "/my/feedback?emotion=soso")
            }
          >
            <Image tag={ImageTypes.SOSO} width={40} height={40} alt="SOSO" />
            <Text tag={FontTypes.BODY_3} color="CONTENT_SECONDARY" center>
              보통
            </Text>
          </Emotion>
          <Emotion
            onClick={(event: React.MouseEvent) =>
              onClickEmotionLink(event, "/my/feedback?emotion=satisfying")
            }
          >
            <Image
              tag={ImageTypes.SATISFYING}
              width={40}
              height={40}
              alt="SATISFYING"
            />
            <Text tag={FontTypes.BODY_3} color="CONTENT_SECONDARY" center>
              만족
            </Text>
          </Emotion>
        </EmotionWrapper>
      </VocWrapper>
    </>
  );
};

export default EarlySettlementBoard;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fefefe;
  padding: 20px;
  gap: 20px;
  border-radius: 20px;
  cursor: default;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #eaeaea;
`;

const HistoryList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HistoryLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  text-decoration: none;
`;

const EarlySettlementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  cursor: default;
`;

const MessageWrapper = styled.div`
  width: 100%;
  padding: 12px;
  background: #fde6ed;
  border-radius: 12px;
  display: flex;
  gap: 6px;
  align-items: flex-start;
`;

const MessageIconBox = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VocWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fefefe;
  padding: 16px 20px;
  gap: 8px;
  border-radius: 20px;
  cursor: pointer;
`;

const EmotionWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const Emotion = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px 12px 4px;
  cursor: pointer;
`;
