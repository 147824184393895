import React from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import MainOldLayout from "@routes/layouts/MainOldLayout";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { FlexBox, Meta, Text } from "@components/commons/index";
import { Header } from "@components/modules/common/index";

import { IMG_URL } from "@/@types/commonType";

const EndEvent = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

// 추후 하드코딩에서 API화 필요
const Notice = () => {
  const navigate = useNavigate();

  return (
    <>
      <Meta title={`얼리페이 | 마이페이지`} name={"이벤트"} />
      <Header _white _backHistory="/my">
        이벤트
      </Header>
      <MainOldLayout _padding>
        <FlexBox
          _style={{
            marginTop: "20px",
            borderRadius: "0.8rem",
            overflow: "hidden",
            boxShadow: "-2px 4px 16px rgba(0, 0, 0, 0.08)",
            border: "1px solid #fefefe",
          }}
          _onClick={() => navigate("/my/invite-event")}
        >
          <img
            src={IMG_URL.event.inviteEvent}
            alt="토글버튼"
            style={{ width: "100%" }}
          />
          <FlexBox _style={{ padding: "0 16px 20px 16px" }}>
            <FlexBox
              _row
              _align
              _style={{ justifyContent: "space-between", padding: "10px 4px" }}
            >
              <Text _medium _style={{ width: "auto", fontSize: "14px" }}>
                [이벤트] 1+1 친구추천 이벤트!!
              </Text>
              <Text
                _style={{ width: "auto", fontSize: "11px", color: "#585F6A" }}
              >
                22.08.02 ~ 23.12.31{" "}
              </Text>
            </FlexBox>
            <Text
              _style={{
                fontSize: "12px",
                color: "#585F6A",
                fontWeight: "300",
                padding: "0 4px",
              }}
            >
              지금 얼리페이 친구추천을 통해 친구가 회원가입을 하면 친구와 사장님
              모두 무료 1개월 증정!
            </Text>
          </FlexBox>
        </FlexBox>
        <FlexBox
          _style={{
            marginTop: "20px",
            borderRadius: "0.8rem",
            overflow: "hidden",
            boxShadow: "-2px 4px 16px rgba(0, 0, 0, 0.08)",
            border: "1px solid #fefefe",
            position: "relative",
          }}
        >
          <img
            src={IMG_URL.event.glodEvent}
            alt="토글버튼"
            style={{ width: "100%" }}
          />
          <FlexBox _style={{ padding: "0 16px 20px 16px" }}>
            <FlexBox
              _row
              _align
              _style={{ justifyContent: "space-between", padding: "10px 4px" }}
            >
              <Text _medium _style={{ width: "auto", fontSize: "14px" }}>
                [이벤트] 빛나는 황금이벤트!!
              </Text>
              <Text
                _style={{ width: "auto", fontSize: "11px", color: "#585F6A" }}
              >
                23.05.01 ~ 23.05.31
              </Text>
            </FlexBox>
            <Text
              _style={{
                fontSize: "12px",
                color: "#585F6A",
                fontWeight: "300",
                padding: "0 4px",
              }}
            >
              얼리페이 투자유치 기념 감사한 마음을 담아 사장님께 빛나는 황금
              한돈을 드립니다!
            </Text>
          </FlexBox>
          <EndEvent>이벤트 종료</EndEvent>
        </FlexBox>
      </MainOldLayout>
    </>
  );
};

export default Notice;
