import { termsCodeTypes } from "@earlypay/shared/typings";
import { changeHtmlToPdf, mergePdfs } from "@earlypay/shared/utils";
import { sentryHook, trackEventMixpanel } from "@earlypay/shared";

export const generateTermsPdfFile = async (terms: termsCodeTypes[]) => {
  try {
    const pdfBlobs: Blob[] = [];

    for (let i = 0; i < terms.length; i++) {
      const code = terms[i];
      const pdfBlob = await changeHtmlToPdf(
        `terms-page-${code}`,
        `terms-${code}.pdf`,
      );
      if (pdfBlob.size > 0) {
        // Ensure the Blob is not empty
        console.warn("Ensure the Blob is not empty");
        pdfBlobs.push(pdfBlob);
      } else {
        console.warn(`PDF for ${code} is empty.`);
      }
    }

    if (pdfBlobs.length === 0) {
      console.error("No PDFs generated. Check the PDF generation process.");
      sentryHook({
        title: "약관 파일 생성에 실패했습니다.",
        error: new Error(
          "No PDFs generated. Check the PDF generation process.",
        ),
      });

      return;
    }

    const mergedPdfBlob = await mergePdfs(pdfBlobs);

    // const pdfUrl = URL.createObjectURL(mergedPdfBlob);
    // window.open(pdfUrl);

    trackEventMixpanel("generate_pdf_file");
    return mergedPdfBlob;
  } catch (error) {
    console.error("Error generating or merging PDFs:", error);

    sentryHook({
      title: "약관 파일 생성에 실패했습니다.",
      error: error,
    });
  }
};
